import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import { toast } from 'react-toastify';

import { Radio } from '@atlaskit/radio';
import Search from '~/components/Search';

import api from '~/services/api';

import {
  CheckboxTableContent,
  Container,
  ErrorMessage,
  TableContainer,
} from './styles';
import FormDefault from '~/components/FormDefault';
import { Col, Row } from 'react-bootstrap';
import InputText from '~/components/Inputs/InputText';
import { useForm } from 'react-hook-form';

import { FormGrupoEmpresa, Loja } from './protocols';
import Checkbox from '@atlaskit/checkbox';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const GrupoDeEmpresa: React.FC = () => {
  const formGrupoEmpresaBlank: FormGrupoEmpresa = {
    cod_grupo_empresa: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    des_grupo_empresa: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    lojas: {
      lojas: [],
      isInvalid: false,
      isRequired: false,
    },
  };

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [init, setInit] = useState(false);
  const [codGrupoEmpresa, setCodGrupoEmpresa] = useState(undefined);

  const [lojasEmpty, setLojasEmpty] = useState<Loja[]>([]);
  const [lojas, setLojas] = useState<Loja[]>([]);
  const [formGrupoEmpresa, setFormGrupoEmpresa] = useState<FormGrupoEmpresa>(
    formGrupoEmpresaBlank,
  );

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'selected',
      headerAlign: 'center',
      headerName: 'Selecionar?',
      width: 110,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CheckboxTableContent>
            <Checkbox
              isChecked={row.selected}
              onChange={() => handleSelectedLoja(row)}
            />
          </CheckboxTableContent>
        );
      },
    },
    {
      field: 'flg_matriz',
      headerAlign: 'center',
      headerName: 'Matriz?',
      width: 110,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CheckboxTableContent>
            <Radio
              value={row.cod_loja}
              isDisabled={!row.selected}
              name="flg_matriz"
              isChecked={row.flg_matriz}
              onChange={handleChangeMatriz}
            />
          </CheckboxTableContent>
        );
      },
    },
    {
      field: 'des_loja',
      headerName: 'Loja',
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    getLojas();
  }, []);

  const handleSelectedLoja = (row: any) => {
    const newLojas = lojas.map((loja: Loja) => {
      if (loja.cod_loja === row.cod_loja) {
        if (row.selected) {
          loja.selected = false;
          loja.flg_matriz = false;
        } else {
          loja.selected = true;
          if (formGrupoEmpresa.lojas.lojas.length === 0) {
            loja.flg_matriz = true;
          }
        }
      }
      return loja;
    });
    setLojas(newLojas);
    setValue('lojas', newLojas);
    handleSelectedLojas(newLojas);
  };

  const handleSelectedLojas = (newLojas: Loja[]) => {
    const selecteds = newLojas.filter((loja: Loja) => {
      return loja.selected;
    });

    setValue('selectedLojas', selecteds);
    setFormGrupoEmpresa({
      ...formGrupoEmpresa,
      lojas: {
        ...formGrupoEmpresa.lojas,
        lojas: selecteds,
      },
    });
  };

  async function getLojas() {
    api
      .get('/grupoempresa/loja')
      .then((res) => {
        const { data, success, message } = res.data;
        if (!success) {
          throw new Error(message);
        }
        const options = data.map((loja: Loja) => {
          return {
            id: loja.cod_loja,
            cod_loja: loja.cod_loja,
            des_loja: `${loja.cod_loja} - ${loja.des_loja}`,
            flg_matriz: loja.flg_matriz || false,
            selected: false,
          };
        });
        setLojas(options);
        setLojasEmpty(options);
      })
      .catch(() => {
        setLojas([]);
      });
  }

  const getGrupoEmpresa = async (cod_grupo_empresa: number) => {
    try {
      const res = await api.get(`/grupoempresa/${cod_grupo_empresa}`);
      const { data } = res.data;

      const options = data.lojas.map((loja: Loja) => {
        return {
          id: loja.cod_loja,
          cod_loja: loja.cod_loja,
          des_loja: `${loja.cod_loja} - ${loja.des_loja}`,
          flg_matriz: loja.flg_matriz,
          selected: true,
        };
      });

      setFormGrupoEmpresa({
        ...formGrupoEmpresa,
        cod_grupo_empresa: {
          ...formGrupoEmpresa.cod_grupo_empresa,
          value: cod_grupo_empresa,
        },
        des_grupo_empresa: {
          value: data.des_grupo_empresa,
          isInvalid: false,
          isRequired: false,
        },
        lojas: {
          ...formGrupoEmpresa.lojas,
          lojas: options,
        },
      });

      setValue('selectedLojas', options);
      setValue('cod_grupo_empresa', data.cod_grupo_empresa);
      setValue('des_grupo_empresa', data.des_grupo_empresa);

      const newLojas = [...options, ...lojas].sort((a, b) => {
        if (a.cod_loja > b.cod_loja) return 1;
        if (a.cod_loja < b.cod_loja) return -1;
        return 0;
      });
      setLojas(newLojas);
    } catch (error) {
      toast.warn((error as Error).message);
    }
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    await getGrupoEmpresa(row.cod_grupo_empresa);
    setCodGrupoEmpresa(row.cod_grupo_empresa);
    setIsUpdate(true);
    setShowSearch(false);
  };

  const onClearForm = useCallback(async () => {
    await getLojas();
    reset();
    setFormGrupoEmpresa(formGrupoEmpresaBlank);
    setInit(false);
  }, [formGrupoEmpresaBlank]);

  const newData = useCallback(async () => {
    if (lojasEmpty.length > 0) {
      onClearForm();
      setShowSearch(false);
      setIsUpdate(false);
    } else {
      return toast.warn('Não há lojas para serem vinculadas');
    }
  }, [lojasEmpty.length, onClearForm]);

  const handleChangeMatriz = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cod_loja = Number(e.target.value);
    const newLojas = lojas.map((loja: Loja) => {
      if (loja.cod_loja === cod_loja) {
        loja.flg_matriz = true;
      } else {
        loja.flg_matriz = false;
      }
      return loja;
    });

    setValue('lojas', newLojas);
    setLojas(newLojas);
  };

  const onSave = handleSubmit(async (dataForm) => {
    setInit(false);

    if (!dataForm.selectedLojas) {
      return toast.warning(
        'É necessário vincular ao menos uma loja ao grupo de empresa',
      );
    }

    const hasMatriz = dataForm.selectedLojas.find((loja: Loja) => {
      return loja.flg_matriz;
    });

    if (!hasMatriz)
      return toast.warning('Defina uma matriz para o grupo de empresa.');

    const upsert = {
      des_grupo_empresa: dataForm.des_grupo_empresa,
      lojas: dataForm.selectedLojas.map((loja: Loja) => ({
        cod_loja: loja.cod_loja,
        flg_matriz: loja.flg_matriz,
      })),
    };

    if (!isUpdate) {
      const res = await api.post('/grupoempresa', upsert);
      const { message } = res.data;
      const lojasNaoEmFormGrupo = lojasEmpty.filter(function (lojaEmpty) {
        return !dataForm.selectedLojas.some(
          (lojaFormGrupo: any) => lojaFormGrupo.cod_loja === lojaEmpty.cod_loja,
        );
      });
      if (lojasNaoEmFormGrupo.length === 0) {
        setShowSearch(true);
      }
      onClearForm();
      await getLojas();
      return toast.success(message);
    }

    const res = await api.put(
      `/grupoempresa/${formGrupoEmpresa.cod_grupo_empresa.value}`,
      upsert,
    );
    const { message } = res.data;
    toast.success(message);
    onClearForm();
    await getLojas();
    setShowSearch(true);
  });

  async function handleDelete() {
    const { data } = await api.delete(
      `/grupoempresa/${formGrupoEmpresa.cod_grupo_empresa.value}`,
    );
    const { message } = data;
    await onClearForm();
    setShowSearch(true);
    toast.success(message);
  }

  const onNew = useCallback(async () => {
    if (lojasEmpty.length === 0) {
      toast.warn('Não há lojas para serem vinculadas');
    } else {
      setIsUpdate(false);
      await onClearForm();
      await getLojas();
    }
  }, [lojasEmpty.length, onClearForm]);

  return (
    <Container>
      {showSearch && (
        <Search codTela={23} newData={newData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={23}
          codigoRegistro={[{ value: codGrupoEmpresa, des_campo: 'Código' }]}
          title="Grupo de Empresas"
          onSave={onSave}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={() => {
            onNew();
          }}
          onDelete={handleDelete}
          onClearFields={onClearForm}
          onReturnSearch={() => {
            getLojas();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col>
              <InputText
                label="Descrição"
                placeholder="Informe a descrição"
                maxLength={50}
                value={formGrupoEmpresa.des_grupo_empresa.value}
                iniInicializado={!!errors.des_grupo_empresa}
                isRequired
                setInvalid={!!errors.des_grupo_empresa}
                onChange={(val) => {
                  setFormGrupoEmpresa({
                    ...formGrupoEmpresa,
                    des_grupo_empresa: {
                      ...formGrupoEmpresa.des_grupo_empresa,
                      value: val.trimStart().toUpperCase(),
                      isInvalid: val === ' ' || val === '',
                    },
                  });

                  setValue('des_grupo_empresa', val.trimStart().toUpperCase());
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12}>
              <TableContainer>
                <DataGrid
                  rowsPerPageOptions={[5]}
                  rows={lojas}
                  columns={columns}
                  pageSize={5}
                  disableColumnSelector
                  disableSelectionOnClick
                  localeText={{
                    noRowsLabel: 'Não há lojas para serem vinculadas',
                    columnMenuLabel: 'Menu',
                    columnMenuFilter: 'Filtrar',
                    columnMenuHideColumn: 'Esconder',
                    columnMenuUnsort: 'Não ordenar',
                    columnMenuSortAsc: 'Ordernar ASC',
                    columnMenuSortDesc: 'Ordernar DESC',
                    columnMenuShowColumns: 'Mostrar columnas',
                  }}
                />
              </TableContainer>
              <ErrorMessage>
                Caso a loja não apareça para vínculo, verifique se não está
                vinculada a outro grupo de empresa
              </ErrorMessage>
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};
export default GrupoDeEmpresa;
