import React, { useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';
import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { InputText } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';

const GrupoDeEtiqueta: React.FC = () => {
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFields = {
    des_grupo: '',
    cod_etiqueta: '',
  };
  const resetFormData = () => {
    reset(resetFields);
    setUpdate(false);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const { cod_grupo_etiqueta, des_grupo } = row;

    setValue('des_grupo', des_grupo);
    setValue('cod_etiqueta', cod_grupo_etiqueta);

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataFields) => {
    const { des_grupo, cod_etiqueta } = dataFields;
    try {
      if (isUpdate) {
        const { data } = await api.put(`/grupo-de-etiqueta/${cod_etiqueta}`, {
          des_grupo: des_grupo.trim(),
        });
        if (data.success) {
          toast.success(data.message);
          setUpdate(false);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/grupo-de-etiqueta', {
        des_grupo: des_grupo.trim(),
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      } else {
        toast.error(data.message);
        setLoader(false);
      }
      setLoader(false);
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const value = getValues();
    const { cod_etiqueta } = value;
    const res = await api.delete(`/grupo-de-etiqueta/${cod_etiqueta}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    setUpdate(false);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={193}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={193}
          codigoRegistro={[
            { value: watch('cod_etiqueta'), des_campo: 'Código' },
          ]}
          title="Grupo de Etiqueta"
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                name="des_grupo"
                register={register}
                control={control}
                maxLength={50}
                placeholder="Informe a Descrição"
                isError={!!errors.des_grupo}
                caseInput="upper"
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default GrupoDeEtiqueta;
