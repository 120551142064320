import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const StyledForm = styled.form`
  padding: 20px;
  border-radius: 5px;
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  height: 350px;
  margin-top: 16px;
`;

export const CheckboxTableContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
